export default {
  "row": "qm body-1",
  "active": "qp",
  "cell": "qx",
  "empty": "qw",
  "ellipsis": "qN",
  "text-secondary": "qv",
  "row-sidebar": "qo",
  "cell-conditions": "qn",
  "animated": "qi",
  "fadein-item": "qA",
  "animated-cell": "qY",
  "pr-24": "qh",
  "rule-with-error": "qf",
  "icon": "qK"
};
