/* import __COLOCATED_TEMPLATE__ from './auth-buttons.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { allSettled, dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

/**
 * Track the load state of Google SDK.
 * The following property could have 3 differents states:
 * - "loading"
 * - "loaded"
 * - "not-loaded“
 */
const SDK_LOADING_STATUS = {
  LOADING: 'loading',
  LOADED: 'loaded',
  NOT_LOADED: 'not-loaded',
};

interface AuthButtonsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AuthButtons extends Component<AuthButtonsSignature> {
  @service declare authSdkLoader: Services['authSdkLoader'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  sdkLoadingStatus = SDK_LOADING_STATUS;

  constructor(owner: unknown, args: AuthButtonsSignature['Args']) {
    super(owner, args);

    this.loadAuthProviderScriptsTask.perform().catch(ignoreCancelation);
  }

  /**
   * @param {'google' | 'apple'} provider
   * @param {unknown | undefined} error
   */
  // @ts-expect-error
  _handleAuthErrors(provider, error) {
    this.segment.track('login_failed', {
      authentication_method: provider,
    });

    if (hasMFAError(error?.responseJSON?.errors)) {
      throw error.responseJSON;
    }

    let { status } = error;
    if (status === 401) {
      this.toastFlashMessages.toastError(this.intl.t('sign_in.errors.external_auth_no_user_match'));
      return;
    }

    if (status === 403) {
      this.toastFlashMessages.toastError(this.intl.t('errors.account_locked'));
      return;
    }

    // Status 500 is already handled by service errors
    if (status !== 500) {
      this.toastFlashMessages.toastError(this.intl.t('sign_in.errors.external_auth_generic_issue'));
    }

    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }

  authGoogleUserTask = dropTask(async ({ jwt }) => {
    try {
      // @ts-expect-error
      await this.args.onSignInGoogleSuccess({ jwt });
    } catch (error) {
      this._handleAuthErrors('google', error);
    }
  });

  authAppleUserTask = dropTask(async ({ jwt }) => {
    try {
      // @ts-expect-error
      await this.args.onSignInAppleSuccess({ jwt });
    } catch (error) {
      this._handleAuthErrors('apple', error);
    }
  });

  @action
  // @ts-expect-error
  onAuthProvidersError(provider, error) {
    if (error.message === 'popup_closed_by_user') {
      return;
    }

    this.toastFlashMessages.toastError(this.intl.t('sign_in.errors.external_auth_generic_issue'));

    let sdkLoadState = provider === 'google' ? this.googleSDKLoadState : this.appleSDKLoadState;

    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && sdkLoadState === SDK_LOADING_STATUS.LOADED) {
      this.sentry.captureException(error);
    }

    this.segment.track('login_failed', {
      authentication_method: provider,
    });
  }

  get googleSDKLoadState() {
    if (this._loadGoogleAuthTask.isRunning) {
      return SDK_LOADING_STATUS.LOADING;
    }

    // @ts-expect-error
    return this._loadGoogleAuthTask.last.isSuccessful
      ? SDK_LOADING_STATUS.LOADED
      : SDK_LOADING_STATUS.NOT_LOADED;
  }

  get appleSDKLoadState() {
    if (this._loadAppleAuthTask.isRunning) {
      return SDK_LOADING_STATUS.LOADING;
    }

    // @ts-expect-error
    return this._loadAppleAuthTask.last.isSuccessful
      ? SDK_LOADING_STATUS.LOADED
      : SDK_LOADING_STATUS.NOT_LOADED;
  }

  get shouldShowGoogleButton() {
    return (
      this.googleSDKLoadState === SDK_LOADING_STATUS.LOADED ||
      this.googleSDKLoadState === SDK_LOADING_STATUS.NOT_LOADED
    );
  }

  get shouldShowAppleButton() {
    return (
      this.appleSDKLoadState === SDK_LOADING_STATUS.LOADED ||
      this.appleSDKLoadState === SDK_LOADING_STATUS.NOT_LOADED
    );
  }

  /**
   * Show the separator when at least one of the auth provider is in loaded or not-loaded state.
   */
  get shouldShowSeparator() {
    let sdkStates = [SDK_LOADING_STATUS.LOADED, SDK_LOADING_STATUS.NOT_LOADED];
    return (
      sdkStates.includes(this.appleSDKLoadState) || sdkStates.includes(this.googleSDKLoadState)
    );
  }

  /**
   * Load both auth providers (Google / Apple) SDK in parallel.
   */
  loadAuthProviderScriptsTask = dropTask(async () => {
    await allSettled([this._loadGoogleAuthTask.perform(), this._loadAppleAuthTask.perform()]);
  });

  _loadGoogleAuthTask = dropTask(async () => {
    // @ts-expect-error
    if (!window.google?.accounts && ENV.environment !== 'test') {
      await this.authSdkLoader.loadGoogleSdk();
    }
  });

  _loadAppleAuthTask = dropTask(async () => {
    // @ts-expect-error
    if (!window.AppleID?.auth && ENV.environment !== 'test') {
      await this.authSdkLoader.loadAppleSdk();
    }
  });

  /**
   * @param {'google' | 'apple'} provider
   */
  // @ts-expect-error
  @action trackButtonClick(provider) {
    this.segment.track('login_clicked', { authentication_method: provider });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AuthButtons: typeof AuthButtons;
  }
}
