/* import __COLOCATED_TEMPLATE__ from './probation-guidelines.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ProbationGuidelinesSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ProbationGuidelines = templateOnlyComponent<ProbationGuidelinesSignature>();

export default ProbationGuidelines;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::ProbationGuidelines': typeof ProbationGuidelines;
  }
}
