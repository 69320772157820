/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { generateConditionString } from 'qonto/utils/approval-workflow/condition';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface ApprovalWorkflowsTableItemSignature {
  // The arguments accepted by the component
  Args: {
    isHighlighted?: boolean;
    isApproveCompactView?: boolean;
    isSidepanelOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class ApprovalWorkflowsTableItem extends Component<ApprovalWorkflowsTableItemSignature> {
  @tracked conditionsString = '–';
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare errors: Services['errors'];

  constructor(owner: unknown, args: ApprovalWorkflowsTableItemSignature['Args']) {
    super(owner, args);
    this.generateConditionsStringTask.perform().catch(ignoreCancelation);
  }

  // @ts-expect-error
  async generateConditionsString(conditions) {
    let allConditionsString = '';

    for (let [index, condition] of conditions.entries()) {
      let { condition: singleConditionString } = await generateConditionString(
        condition,
        index,
        this.store,
        this.intl,
        this.errors
      );
      if (!singleConditionString) continue;

      allConditionsString = allConditionsString
        ? `${allConditionsString}, ${singleConditionString}`
        : singleConditionString;
    }

    return allConditionsString || '–';
  }

  get cellIcon() {
    // @ts-expect-error
    let { workflow } = this.args;

    switch (workflow.requestType) {
      case 'transfer':
        return 'feature_transfer_direct-l';
      case 'reimbursement':
        return 'reimbursement';
      case 'supplier_invoice':
        return 'supplier-invoice';
    }
  }

  get cellTitle() {
    // @ts-expect-error
    let { workflow } = this.args;
    switch (workflow.requestType) {
      case 'transfer':
        return this.intl.t('approval-workflows.table.item.transfer-requests');
      case 'reimbursement':
        return this.intl.t('approval-workflows.table.item.reimbursement-requests');
      case 'supplier_invoice':
        return this.intl.t('approval-workflows.table.item.supplier-invoice-requests');
    }
  }

  generateConditionsStringTask = dropTask(async () => {
    // @ts-expect-error
    let { workflow } = this.args;
    // @ts-expect-error
    let conditions = workflow.rulesets?.map(ruleset => ruleset.conditions[0]);

    this.conditionsString = await this.generateConditionsString(conditions);
  });

  get hasErrors() {
    // @ts-expect-error
    let { workflow } = this.args;
    return workflow.workflowErrors?.length > 0;
  }

  @action
  // @ts-expect-error
  stopPropagation(event) {
    event.stopPropagation();
  }

  @action
  // @ts-expect-error
  onRowClick(id) {
    // @ts-expect-error
    this.args.highlightItem(id);
  }

  @action
  onEditWorkflow() {
    this.segment.track('approval-section_workflow-list-edit-button_clicked');
    this.flowLinkManager.transitionTo({
      name: 'approval-workflows',
      stepId: 'builder',
      // @ts-expect-error
      queryParams: { id: this.args.workflow.id },
    });
  }

  get shouldDisplayUpdateDate() {
    // @ts-expect-error
    return !this.args.workflow.isDefault && this.args.workflow.updatedAt;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Table::Item': typeof ApprovalWorkflowsTableItem;
  }
}
