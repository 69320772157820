/* import __COLOCATED_TEMPLATE__ from './confirm-delete-modal.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface ConfirmDeleteModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ConfirmDeleteModalComponent extends Component<ConfirmDeleteModalSignature> {
  @service declare intl: Services['intl'];

  get translations() {
    return {
      title: this.intl.t('transactions.modals.delete-attachment.title'),
      description: this.intl.t('transactions.modals.delete-attachment.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('transactions.modals.delete-attachment.delete-attachment'),
    };
  }

  get data() {
    return { ...this.translations, confirmTask: this.confirmTask };
  }

  confirmTask = dropTask(async () => {
    // @ts-expect-error
    let { confirmTask, model, file } = this.args.data;

    await confirmTask.linked().perform(model, file);
    // @ts-expect-error
    this.args.close();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::ConfirmDeleteModal': typeof ConfirmDeleteModalComponent;
  }
}
