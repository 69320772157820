/* import __COLOCATED_TEMPLATE__ from './error-state.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ErrorStateSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ErrorState = templateOnlyComponent<ErrorStateSignature>();

export default ErrorState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Table::ErrorState': typeof ErrorState;
  }
}
