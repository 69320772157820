/* import __COLOCATED_TEMPLATE__ from './approvals.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { OPERATION_TYPES, REQUEST_TYPES } from 'qonto/constants/approval-workflow';
// @ts-expect-error
import { STATUS } from 'qonto/constants/approval-workflow-state';
import { ROLES } from 'qonto/constants/membership';

const NUM_OF_NAMES_TO_DISPLAY = 2;

// @ts-expect-error
function getApprovalWorkflowTransferAndReimbursementTranslations(intl) {
  return {
    completed: {
      payment: {
        [STATUS.APPROVED]: intl.t('approval-workflows.ruleset.steps.payment-made'),
        [STATUS.REJECTED]: intl.t('approval-workflows.requests.status.rejected-by'),
      },
      approval: {
        [STATUS.APPROVED]: intl.t('approval-workflows.requests.status.approved-by'),
        [STATUS.REJECTED]: intl.t('approval-workflows.requests.status.rejected-by'),
      },
    },
    pending: {
      payment: intl.t('approval-workflows.ruleset.steps.payment'),
      approval: {
        one_of: intl.t('approval-workflows.ruleset.steps.equal-any'),
        all_of: intl.t('approval-workflows.ruleset.steps.equal-all'),
      },
    },
  };
}

// @ts-expect-error
function getApprovalWorkflowSupplierInvoiceTranslations(intl) {
  return {
    completed: {
      approval: {
        [STATUS.APPROVED]: intl.t('approval-workflows.ruleset.steps.supplier-invoices.approved-by'),
        [STATUS.REJECTED]: intl.t('approval-workflows.ruleset.steps.supplier-invoices.rejected-by'),
      },
      payment: {
        [STATUS.APPROVED]: intl.t('approval-workflows.ruleset.steps.payment-made'),
        [STATUS.REJECTED]: intl.t('approval-workflows.ruleset.steps.supplier-invoices.rejected-by'),
      },
    },
    pending: {
      payment: intl.t('approval-workflows.ruleset.steps.payment'),
      approval: {
        one_of: intl.t(
          'approval-workflows.ruleset.steps.approvers-list.supplier-invoices.equal-any'
        ),
        all_of: intl.t(
          'approval-workflows.ruleset.steps.approvers-list.supplier-invoices.equal-all'
        ),
      },
    },
  };
}

// @ts-expect-error
function getApprovalWorkflowTranslations(intl) {
  return {
    [REQUEST_TYPES.TRANSFER]: { ...getApprovalWorkflowTransferAndReimbursementTranslations(intl) },
    [REQUEST_TYPES.REIMBURSEMENT]: {
      ...getApprovalWorkflowTransferAndReimbursementTranslations(intl),
    },
    [REQUEST_TYPES.MILEAGE]: { ...getApprovalWorkflowTransferAndReimbursementTranslations(intl) },
    [REQUEST_TYPES.EXPENSE_REPORT]: {
      ...getApprovalWorkflowTransferAndReimbursementTranslations(intl),
    },
    [REQUEST_TYPES.SUPPLIER_INVOICE]: { ...getApprovalWorkflowSupplierInvoiceTranslations(intl) },
  };
}

interface ApprovalWorkflowRulesetAttrVisualizerConditionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ApprovalWorkflowRulesetAttrVisualizerConditions extends Component<ApprovalWorkflowRulesetAttrVisualizerConditionsSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];

  get approvers() {
    // @ts-expect-error
    let { isWorkflowStateStatus } = this.args;
    let approvers = isWorkflowStateStatus
      ? this.requestStateApprovers
      : this.approvalWorkflowApprovers;
    return approvers;
  }

  // This is the list of approvers displayed on the requests sidebars
  get requestStateApprovers() {
    // @ts-expect-error
    let { approvers, value } = this.args.step;
    if (this.isCompleted) {
      // @ts-expect-error
      return approvers.map(approver => approver.full_name);
    }
    // @ts-expect-error
    return value.map(member => {
      return member.full_name || member.fullName;
    });
  }

  // This is the list of approvers that we display on the approval-workflows page
  get approvalWorkflowApprovers() {
    // @ts-expect-error
    let approversIds = this.args.step?.value || [];
    let members = this.store
      .peekAll('membership')
      .filter(({ id }) => approversIds.includes(id))
      .map(member => member.fullName);

    let roles = approversIds
      // @ts-expect-error
      .filter(id => id === ROLES.ADMIN || id === ROLES.MANAGER)
      // @ts-expect-error
      .map(role => {
        if (role === ROLES.ADMIN) return this.intl.t('approval-workflows.possible-approvers.admin');
        return this.intl.t('approval-workflows.possible-approvers.manager');
      });

    return [...members, ...roles];
  }

  get approverNames() {
    let approversCount = this.approvers.length || 0;
    let translationParameters = {
      count: approversCount,
      managerNameOne: this.approvers[0],
      managerNameTwo: this.approvers[1],
      remaining: approversCount - NUM_OF_NAMES_TO_DISPLAY,
    };

    if (this.operationType === OPERATION_TYPES.ONE_OF) {
      return this.intl.t(
        'approval-workflows.ruleset.steps.approvers-list.equal-any',
        translationParameters
      );
    }

    return this.intl.t(
      'approval-workflows.ruleset.steps.approvers-list.equal-all',
      translationParameters
    );
  }

  get operationType() {
    // @ts-expect-error
    return this.args.step?.operation;
  }

  get isCompleted() {
    // @ts-expect-error
    return [STATUS.APPROVED, STATUS.REJECTED].includes(this.args.step?.status);
  }

  get warningMessage() {
    if (!this.isCompleted && this.approvers.length === 0) {
      if (this.abilities.can('create approval-workflow')) {
        return this.intl.t('approval-workflows.requests.status.tooltip-approver');
      } else {
        return this.intl.t('approval-workflows.requests.status.tooltip-requester');
      }
    }
  }

  get requestType() {
    // @ts-expect-error
    return this.args.requestType;
  }

  /**
   * Getter with fallback to TRANSFER used for translations
   * Consider using this.requestType instead
   * @returns {REQUEST_TYPES}
   */
  get requestTypeWithFallback() {
    // @ts-expect-error
    return this.args.requestType || REQUEST_TYPES.TRANSFER;
  }

  get approvalDescription() {
    // @ts-expect-error
    let { isPaymentStep, step } = this.args;
    let state = this.isCompleted ? 'completed' : 'pending';
    let stepType = isPaymentStep ? 'payment' : 'approval';

    // @ts-expect-error
    let translations = getApprovalWorkflowTranslations(this.intl)[this.requestTypeWithFallback][
      state
    ][stepType];

    if (this.isCompleted) {
      return translations[step?.status];
    }

    return isPaymentStep ? translations : translations[this.operationType];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Visualizer::Approvals': typeof ApprovalWorkflowRulesetAttrVisualizerConditions;
  }
}
