export default {
  "attachments-suggested-sidebar": "RP",
  "aside-section": "Rl",
  "search-section": "RX",
  "scrolled-top": "RC",
  "aside-title": "Rk caption-bold",
  "search-input": "RJ",
  "toolbar-row": "Rs",
  "invoices-list-section": "RF",
  "attachments-suggested-sidebar-footer": "Rg",
  "pagination-wrapper": "RD",
  "pagination-loading": "RV",
  "cta-wrapper": "RL",
  "cancel-button": "RT",
  "content": "Rz",
  "illustration": "Ra mb-32",
  "title": "RH mb-8 title-4",
  "subtitle": "RO body-2"
};
