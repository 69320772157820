/* import __COLOCATED_TEMPLATE__ from './attachment-viewer-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface AttachmentsAttachmentViewerModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AttachmentsAttachmentViewerModalComponent extends Component<AttachmentsAttachmentViewerModalSignature> {
  @action
  // @ts-expect-error
  close(reason) {
    if (typeof reason !== 'string') {
      reason = 'close';
    }
    // @ts-expect-error
    this.args.close(reason);
    // @ts-expect-error
    this.args.data.transaction.reload();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::AttachmentViewerModal': typeof AttachmentsAttachmentViewerModalComponent;
  }
}
