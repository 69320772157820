/* import __COLOCATED_TEMPLATE__ from './cell.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CellSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Cell = templateOnlyComponent<CellSignature>();

export default Cell;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Table::Cell': typeof Cell;
  }
}
