/* import __COLOCATED_TEMPLATE__ from './upsell.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { EmptyStatesDiscover } from '@repo/domain-kit/pricing';
import { dropTask } from 'ember-concurrency';

import { REQUEST_TYPES } from 'qonto/constants/approval-workflow';
import { Header } from 'qonto/react/components/approval-workflow/table/header';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface ApprovalWorkflowsUpsellSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ApprovalWorkflowsUpsell extends Component<ApprovalWorkflowsUpsellSignature> {
  header = Header;

  emptyStatesDiscover = EmptyStatesDiscover;
  BADGE_TYPE = BADGE_TYPE;

  @service declare intl: Services['intl'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare abilities: Services['abilities'];

  get hasAvailableFreeTrial() {
    return this.upsellManager.hasAvailableFreeTrial('approval_workflows');
  }

  ctaAddToPlanText;
  ctaFreeTrialText;

  constructor(owner: unknown, args: ApprovalWorkflowsUpsellSignature['Args']) {
    super(owner, args);
    this.segment.track('empty_state_shown', {
      'empty-state_type': 'upsell',
      'empty-state_feature': 'approval-workflows',
    });
    // Initialize the values in the constructor to avoid getting node hierarchy error
    // when Ember is updating, React is re-rendering, and somewhere in that cycle, a parent-child DOM relationship gets messed up
    this.ctaAddToPlanText = this.abilities.can('try-before-buy approval-workflow')
      ? this.intl.t('approval-workflows.empty-state.get-started')
      : this.intl.t('upsell.add-ons.cta');

    this.ctaFreeTrialText = this.abilities.can('try-before-buy approval-workflow')
      ? this.intl.t('approval-workflows.empty-state.get-started')
      : this.intl.t('empty-states.system.try.cta');
  }

  get transferWorkflow() {
    // @ts-expect-error
    let allWorflows = [...this.args.workflows] || [];
    return allWorflows?.find(({ requestType }) => requestType === REQUEST_TYPES.TRANSFER);
  }

  onCtaClickTask = dropTask(async () => {
    await this.upsellManager.upgradeRecommendationWithTransition('approval_workflows');
  });

  @action
  onCtaClick() {
    let canTryBeforeUpsell = this.abilities.can('try-before-buy approval-workflow');
    this.segment.track('approval-workflow_CTA_clicked', {
      cta_type: 'upsell',
      aw_upsell_type: canTryBeforeUpsell ? 'get_started' : 'add_to_plan',
    });

    if (canTryBeforeUpsell) {
      this.flowLinkManager.transitionTo({
        name: 'approval-workflows',
        stepId: 'builder',
        queryParams: { id: this.transferWorkflow?.id },
      });
    } else {
      this.onCtaClickTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Upsell': typeof ApprovalWorkflowsUpsell;
  }
}
