/* import __COLOCATED_TEMPLATE__ from './scan-animation.hbs'; */
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

interface AttachmentsScanAnimationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AttachmentsScanAnimationComponent extends Component<AttachmentsScanAnimationSignature> {
  lottiePlayer = LottiePlayer;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::ScanAnimation': typeof AttachmentsScanAnimationComponent;
  }
}
