/* import __COLOCATED_TEMPLATE__ from './google-auth-button.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, waitForQueue } from 'ember-concurrency';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface GoogleAuthButtonSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class GoogleAuthButton extends Component<GoogleAuthButtonSignature> {
  @service declare localeManager: Services['localeManager'];
  @service declare segment: Services['segment'];
  btnContainerId = `btn-container-${guidFor(this)}`;

  constructor(owner: unknown, args: GoogleAuthButtonSignature['Args']) {
    super(owner, args);
    this.initGoogleAuthTask.perform().catch(ignoreCancelation);
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    window.removeEventListener('message', this._onHandleMessagesFromGoogleIframe);
  }

  @action
  // @ts-expect-error
  _onHandleMessagesFromGoogleIframe(event) {
    let { channelId, type } = event?.data ?? {};
    if (channelId && type === 'readyForConnect') {
      this.segment.track('login_clicked', { authentication_method: 'google' });
    }
  }

  @action
  // @ts-expect-error
  _handleCredentialResponse(response) {
    // @ts-expect-error
    this.args.onSignInSuccess?.({ jwt: response?.credential });
  }

  @action
  _onHandleInitFailureFallback() {
    // @ts-expect-error
    this.args.onError?.(new Error('Failed to load Google SDK'));
  }

  initGoogleAuthTask = dropTask(async () => {
    await waitForQueue('afterRender');
    let btnContainer = document.getElementById(this.btnContainerId);
    // @ts-expect-error
    if (this.args.shouldUseInitFailureFallback) {
      return;
    }
    try {
      window.addEventListener('message', this._onHandleMessagesFromGoogleIframe);
      // @ts-expect-error
      window.google?.accounts?.id?.initialize?.({
        // @ts-expect-error
        client_id: ENV.googleAuth.clientID,
        callback: this._handleCredentialResponse,
        context: 'signin',
      });
      // @ts-expect-error
      window.google?.accounts?.id?.renderButton?.(btnContainer, {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: 'continue_with',
        locale: this.localeManager.locale,
        shape: 'square',
        logo_alignment: 'center',
        width: 350,
      });
    } catch (error) {
      // @ts-expect-error
      this.args.onError?.(error);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AuthButtons::GoogleAuthButton': typeof GoogleAuthButton;
  }
}
