/* import __COLOCATED_TEMPLATE__ from './attachment-viewer-sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface AttachmentsAttachmentViewerSidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class AttachmentsAttachmentViewerSidebarComponent extends Component<AttachmentsAttachmentViewerSidebarSignature> {
  @service declare abilities: Services['abilities'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare intl: Services['intl'];

  get hasAccountingValue() {
    // @ts-expect-error
    let { completed, pending } = this.args.transaction;
    return completed || pending;
  }

  get canDisplayBookkeeping() {
    // @ts-expect-error
    let { isBilling, isPagoPaPayment, reversed } = this.args.transaction;
    return (
      // @ts-expect-error
      this.args.context === 'bookkeeping' ||
      ((this.hasAccountingValue || reversed) && !(isBilling || isPagoPaPayment))
    );
  }

  get canDisplayAccounting() {
    return (
      // @ts-expect-error
      this.args.context === 'bookkeeping' ||
      (this.hasAccountingValue && this.abilities.can('qualify for accounting transactions'))
    );
  }

  get canDisplayAccountingReview() {
    // @ts-expect-error
    return this.canDisplayAccounting && !this.args.transaction.isPagoPaPayment;
  }

  @action
  // @ts-expect-error
  saveLabel(labelList, label) {
    // @ts-expect-error
    this.args.saveLabel(labelList, label, 'bookkeeping_details');
  }

  @action
  trackToggleShowMore() {
    this.segment.track('transaction_analytic_label_expand_clicked', {
      source: 'bookkeeping_details',
    });
  }

  submitQualifiedTask = dropTask(async () => {
    // @ts-expect-error
    let transactionIds = [this.args.transaction.id];
    // @ts-expect-error
    let qualified = !this.args.transaction.qualifiedForAccounting;

    try {
      // @ts-expect-error
      await this.args.markAsReviewTask(qualified, transactionIds);

      if (qualified) {
        this.segment.track('attachment_modale_transaction_mark_as_verified_succeeded');
        // @ts-expect-error
        this.args.close();

        return this.toastFlashMessages.toastSuccess(
          this.intl.t('attachment-viewer.verified.footer.qualified.toast.success')
        );
      } else {
        this.segment.track('attachment_modale_transaction_cancel_verification_succeeded');

        return this.toastFlashMessages.toastSuccess(
          this.intl.t('attachment-viewer.verified.footer.disqualified.toast.success')
        );
      }
    } catch (error) {
      this.sentry.captureException(
        new Error(`Transaction submit qualified triggered an error`, { cause: error })
      );

      await this.toastFlashMessages.toastError(
        this.intl.t('attachment-viewer.bookkeeping.generic-error.toast')
      );
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::AttachmentViewerSidebar': typeof AttachmentsAttachmentViewerSidebarComponent;
  }
}
