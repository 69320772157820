/* import __COLOCATED_TEMPLATE__ from './attachment-viewer-nav.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AttachmentViewerNavSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AttachmentViewerNav = templateOnlyComponent<AttachmentViewerNavSignature>();

export default AttachmentViewerNav;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::AttachmentViewerNav': typeof AttachmentViewerNav;
  }
}
