import { template as template_96f7eed4ab3b45b2a1d0e1f575e424f7 } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import SelectorAdd from '@qonto/ui-kit/components/selector/add';
import { dropTask } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import preventDefault from 'ember-event-helpers/helpers/prevent-default';
import { t } from 'ember-intl';
import { variation } from 'ember-launch-darkly';
import eq from 'ember-truth-helpers/helpers/eq';
import not from 'ember-truth-helpers/helpers/not';
import { APPROVAL_WORKFLOW_CONDITION_FIELDS, OPERATION_TYPES, REQUEST_TYPES, VALIDATION_ERRORS } from 'qonto/constants/approval-workflow';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import styles from './form.strict-module.css';
import ApprovalWorkflowFormRuleset from './form/ruleset';
const MAX_RULESETS = 10;
const DEFAULT_RULESET_CONDITION = {
    supplier_id: {
        field: 'supplier_id',
        operation: 'one_of',
        value: {
            supplier_ids: []
        }
    },
    amount: {
        field: 'amount',
        operation: 'gte',
        value: {
            value: '0.00',
            currency: 'EUR'
        }
    },
    uploader_id: {
        field: 'uploader_id',
        operation: 'one_of',
        value: {
            uploader_ids: []
        }
    }
};
export default class ApprovalWorkflowForm extends Component {
    @service
    intl;
    @service
    store;
    @service
    modals;
    @service
    sentry;
    @service
    toastFlashMessages;
    @service
    upsellManager;
    @service
    abilities;
    @service
    segment;
    get workflow() {
        return this.args.workflow;
    }
    get rulesetDeleteButtonStates() {
        let { workflow } = this.args;
        return (workflow?.rulesets?.map((_, index)=>index > 0 || workflow.requestType === REQUEST_TYPES.SUPPLIER_INVOICE) ?? []);
    }
    openAddonCheckoutTask = dropTask(async ()=>{
        try {
            let { recommended_recurrence: recurrence, recommended_product: { code } } = await this.upsellManager.getUpgradeRecommendation('approval_workflows');
            this.args.context.recurrence = recurrence;
            this.args.context.addonCode = code;
            this.args.pushFlow('addon-change', 'addons');
        } catch (error) {
            this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
            if (ErrorInfo.for(error).shouldSendToSentry) {
                this.sentry.captureException(error);
            }
        }
    });
    saveWorkflowTask = dropTask(async ()=>{
        if (this.abilities.can('try-before-buy approval-workflow') && this.abilities.can('update subscription')) {
            this.segment.track('approval-workflow-edit_continue-button_clicked');
            return this.openAddonCheckoutTask.perform().catch(ignoreCancelation);
        }
        let { rulesets } = this.args.workflow;
        let hasErrors = rulesets?.some((_, rulesetIndex)=>{
            if (rulesetIndex === 0) return false;
            if (rulesets[rulesetIndex].conditions[0].field === APPROVAL_WORKFLOW_CONDITION_FIELDS.AMOUNT) {
                this.validateConditionAmount(rulesetIndex);
            }
            return rulesets[rulesetIndex].conditions.some((condition)=>condition.errors.length > 0);
        });
        if (hasErrors) return;
        this.#organizeSteps();
        await this.args.workflow.save();
        this.args.onSave();
    });
    #organizeSteps() {
        this.args.workflow.rulesets.forEach((ruleset)=>{
            // BE expects steps to be ordered by 'order'
            let steps = [
                ...ruleset.steps
            ].sort((a, b)=>a.order - b.order);
            // Make sure that there are no gaps in order numbers
            steps = steps.map((step, index, steps)=>{
                if (index === 0 && step.order !== 1) step.order = 1;
                if (index > 0 && step.order > steps[index - 1].order + 1) {
                    step.order = steps[index - 1].order + 1;
                }
                return step;
            });
            ruleset.steps = steps;
        });
    }
    #checkConditionsAmounts(rulesets, rulesetIndex, conditionToValidate) {
        conditionToValidate.errors.remove('value');
        let amountToValidate = parseFloat(conditionToValidate.value.value, 10);
        for (let [index, ruleset] of rulesets.entries()){
            let amountToValidateAgainst = parseFloat(ruleset.conditions.at(0).value.value, 10);
            if (isNaN(amountToValidate)) {
                conditionToValidate.errors.add('value', VALIDATION_ERRORS.CONDITION_AMOUNT_EMPTY);
                return false;
            }
            if (index < rulesetIndex && amountToValidate < amountToValidateAgainst) {
                conditionToValidate.errors.add('value', VALIDATION_ERRORS.CONDITION_AMOUNT_LOWER);
                return false;
            }
            if (index !== rulesetIndex && amountToValidate === amountToValidateAgainst) {
                conditionToValidate.errors.add('value', VALIDATION_ERRORS.CONDITION_AMOUNT_SAME);
                return false;
            }
            if (index > rulesetIndex && amountToValidate > amountToValidateAgainst) {
                conditionToValidate.errors.add('value', VALIDATION_ERRORS.CONDITION_AMOUNT_HIGHER);
                return false;
            }
        }
        return true;
    }
    @action
    validateConditionAmount(rulesetIndex) {
        let { rulesets } = this.args.workflow;
        let conditionToValidate = rulesets[rulesetIndex].conditions.at(0);
        let isValid = this.#checkConditionsAmounts(rulesets, rulesetIndex, conditionToValidate);
        if (isValid) {
            let hasRulesetsAnyConditionsErrors = rulesets.some((ruleset)=>ruleset.conditions.at(0).errors.length > 0);
            if (hasRulesetsAnyConditionsErrors) {
                for (let [index, ruleset] of rulesets.entries()){
                    let errors = ruleset.conditions.at(0).errors.get('value');
                    if (errors) this.#checkConditionsAmounts(rulesets, index, ruleset.conditions.at(0));
                }
            }
        }
    }
    @action
    removeRuleset(workflow, removalIndex) {
        workflow.rulesets = workflow.rulesets.filter((ruleset, index)=>index !== removalIndex);
    }
    get cannotAddNewRuleset() {
        let { workflow } = this.args;
        return workflow?.rulesets?.length === MAX_RULESETS;
    }
    get defaultRulesetCondition() {
        let { workflow } = this.args;
        let { rulesets } = workflow;
        let firstRulesetField = rulesets[0]?.conditions[0]?.field;
        let lastRulesetField = rulesets.at(-1)?.conditions[0]?.field;
        if (variation('feature--boolean-improve-builder-aw4si') && workflow.requestType === REQUEST_TYPES.SUPPLIER_INVOICE && lastRulesetField) {
            return DEFAULT_RULESET_CONDITION[lastRulesetField];
        }
        if (firstRulesetField) {
            return DEFAULT_RULESET_CONDITION[firstRulesetField];
        }
        switch(workflow.requestType){
            case REQUEST_TYPES.SUPPLIER_INVOICE:
                return DEFAULT_RULESET_CONDITION.supplier_id;
            case REQUEST_TYPES.REIMBURSEMENT:
            case REQUEST_TYPES.TRANSFER:
            default:
                return DEFAULT_RULESET_CONDITION.amount;
        }
    }
    @action
    addNewRuleset() {
        if (this.cannotAddNewRuleset) {
            return;
        }
        let { workflow } = this.args;
        let condition = this.store.createRecord('approval-workflow/condition', this.defaultRulesetCondition);
        let step = this.store.createRecord('approval-workflow/step', {
            order: 1,
            ...(variation('feature--boolean-improve-builder-aw4si') ? {
                operation: OPERATION_TYPES.ONE_OF
            } : {})
        });
        let ruleset = this.store.createRecord('approval-workflow/ruleset', {
            conditions: [
                condition
            ],
            steps: [
                step
            ]
        });
        workflow.rulesets = [
            ...workflow.rulesets,
            ruleset
        ];
    }
    onConditionChangeTask = dropTask(async (condition, attrs)=>{
        let { workflow } = this.args;
        if (variation('feature--boolean-improve-builder-aw4si')) {
            this.updateCondition(workflow, condition, attrs);
            return;
        }
        // Remove with the feature--boolean-improve-builder-aw4si flag
        if (workflow.requestType !== REQUEST_TYPES.SUPPLIER_INVOICE || workflow.rulesets.length === 1 || !attrs.field) {
            this.updateCondition(workflow, condition, attrs);
            return;
        }
        let result = await this.showTypeChangeModal(attrs.field, condition.field);
        if (result === 'confirm') {
            this.updateCondition(workflow, condition, attrs);
            workflow.rulesets[0].conditions = [
                condition
            ];
            workflow.rulesets = [
                workflow.rulesets[0]
            ];
        }
    });
    updateCondition(workflow, condition, attrs) {
        condition.errors.remove('value');
        condition.field = attrs.field ?? condition.field;
        condition.operation = attrs.operation ?? condition.operation;
        condition.value = attrs.value ?? condition.value;
    }
    // Remove with the feature--boolean-improve-builder-aw4si flag
    async showTypeChangeModal(targetType, previousType) {
        return await this.modals.open('popup/destructive', {
            title: this.intl.t('approval-workflows.form.type-change-modal.title'),
            description: this.intl.t('approval-workflows.form.type-change-modal.subtitle', {
                condition_type_1: this.getConditionTypeCopy(targetType),
                condition_type_2: this.getConditionTypeCopy(previousType)
            }),
            cancel: this.intl.t('approval-workflows.form.type-change-modal.cancel'),
            confirm: this.intl.t('approval-workflows.form.type-change-modal.cta')
        });
    }
    getConditionTypeCopy(field) {
        switch(field){
            case APPROVAL_WORKFLOW_CONDITION_FIELDS.SUPPLIER_ID:
                return this.intl.t('approval-workflows.form.conditions.types.supplier');
            case APPROVAL_WORKFLOW_CONDITION_FIELDS.AMOUNT:
                return this.intl.t('approval-workflows.form.conditions.types.amount');
            case APPROVAL_WORKFLOW_CONDITION_FIELDS.UPLOADER_ID:
                return this.intl.t('approval-workflows.form.conditions.types.import');
        }
    }
    get approvalLabel() {
        if (this.workflow.requestType === REQUEST_TYPES.SUPPLIER_INVOICE) {
            return this.intl.t('approval-workflows.form.steps.label-supplier-invoices');
        }
        return this.intl.t('approval-workflows.form.steps.label');
    }
    get ctaText() {
        if (this.abilities.can('try-before-buy approval-workflow')) {
            return this.intl.t('approval-workflows.form.btn.continue');
        }
        return this.intl.t('approval-workflows.form.btn.save');
    }
    nextRulesetConditionAmount(rulesets, ruleset, rulesetIndex) {
        if (ruleset.conditions[0].field !== APPROVAL_WORKFLOW_CONDITION_FIELDS.AMOUNT) {
            return null;
        }
        let currentAmount = ruleset.conditions[0].value?.value;
        if (!currentAmount) return null;
        let higherAmountCondition = rulesets?.slice(rulesetIndex + 1)?.flatMap((ruleset)=>ruleset.conditions)?.find((condition)=>condition.field === APPROVAL_WORKFLOW_CONDITION_FIELDS.AMOUNT)?.value?.value;
        return higherAmountCondition ?? null;
    }
    selectedSupplierIdsInOtherConditions(rulesets, rulesetIndex) {
        return rulesets?.reduce((ids, ruleset, index)=>{
            if (index !== rulesetIndex) {
                ids.push(...(ruleset.conditions[0].value.supplier_ids || []));
            }
            return ids;
        }, []);
    }
    selectedUploaderIdsInOtherConditions(rulesets, rulesetIndex) {
        return rulesets?.reduce((ids, ruleset, index)=>{
            if (index !== rulesetIndex) {
                ids.push(...(ruleset.conditions[0].value.uploader_ids || []));
            }
            return ids;
        }, []);
    }
    displayRulesetDividerCaption(workflowType, rulesetIndex) {
        return (variation('feature--boolean-improve-builder-aw4si') && workflowType === REQUEST_TYPES.SUPPLIER_INVOICE && rulesetIndex > 0);
    }
    static{
        template_96f7eed4ab3b45b2a1d0e1f575e424f7(`
    <div ...attributes>
      <h1 class='mb-16 title-1' data-test-approval-workflow-form-title>{{@title}}</h1>
      {{#if @subtitle}}
        <p class='body-1 mb-24' data-test-approval-workflow-form-subtitle>
          {{@subtitle}}
        </p>
      {{/if}}
      <form
        class='body-1'
        {{on 'submit' (preventDefault (perform this.saveWorkflowTask))}}
        data-test-approval-workflow-form
      >
        {{#each @workflow.rulesets as |ruleset index|}}
          {{#if (this.displayRulesetDividerCaption @workflow.requestType index)}}
            <p class='mb-16' data-test-form-ruleset-divider-caption>
              {{t 'approval-workflows.form.rulesets.divider-caption'}}
            </p>
          {{/if}}
          <ApprovalWorkflowFormRuleset
            @amountOnly={{not (eq @workflow.requestType REQUEST_TYPES.SUPPLIER_INVOICE)}}
            @approverOptions={{@approverOptions}}
            @payerOptions={{@payerOptions}}
            @index={{index}}
            @ruleset={{ruleset}}
            @nextRulesetConditionAmount={{this.nextRulesetConditionAmount
              @workflow.rulesets
              ruleset
              index
            }}
            @selectedSupplierIdsInOtherConditions={{this.selectedSupplierIdsInOtherConditions
              @workflow.rulesets
              index
            }}
            @selectedUploaderIdsInOtherConditions={{this.selectedUploaderIdsInOtherConditions
              @workflow.rulesets
              index
            }}
            @validate={{fn this.validateConditionAmount index}}
            @removeRuleset={{fn this.removeRuleset @workflow index}}
            @approvalLabel={{this.approvalLabel}}
            @displayApproversHint={{@displayApproversHint}}
            @approversHintMessage={{@approversHintMessage}}
            @displayPayersHint={{@displayPayersHint}}
            @payersHintMessage={{@payersHintMessage}}
            @shouldDisplayDeleteButton={{get this.rulesetDeleteButtonStates index}}
            @onConditionChange={{perform this.onConditionChangeTask}}
            @requestType={{@workflow.requestType}}
            class='mb-16'
          />
        {{/each}}
        <SelectorAdd
          @title={{t 'approval-workflows.form.btn.add-rule'}}
          class={{styles.add-rule-button}}
          @onClick={{this.addNewRuleset}}
          @disabled={{this.cannotAddNewRuleset}}
          data-test-approval-workflow-form-add-rule-button
        />

        {{yield to='disclaimers'}}

        <button
          aria-disabled={{this.submitButtonIsDisabled}}
          disabled={{@disableSubmitButton}}
          type='submit'
          class='btn btn--primary {{styles.submit-button}}'
          data-test-approval-workflow-form-submit-button
        >{{this.ctaText}}</button>
      </form>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
