/* import __COLOCATED_TEMPLATE__ from './billing.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { not } from 'macro-decorators';

interface AttachmentsSidebarBillingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AttachmentsSidebarBillingComponent extends Component<AttachmentsSidebarBillingSignature> {
  // @ts-expect-error
  @not('args.transaction.declined') hasAccountingValue;

  @service declare abilities: Services['abilities'];

  get showVAT() {
    // @ts-expect-error
    let { vat } = this.args.transaction;

    if (!vat) return false;

    let filledVAT = vat.totalAmount?.value !== null;

    return this.abilities.can('view vat bookkeeping') && filledVAT;
  }

  get showAnalyticLabels() {
    return (
      this.abilities.can('access custom-label') &&
      this.abilities.can('read custom-label') &&
      this.hasAccountingValue
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::Sidebar::Bookkeeping::Billing': typeof AttachmentsSidebarBillingComponent;
  }
}
