/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DetailsSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Details = templateOnlyComponent<DetailsSignature>();

export default Details;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Sidebar::Details': typeof Details;
  }
}
