/* import __COLOCATED_TEMPLATE__ from './attachment-viewer-modal-wrapper.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { restartableTask, task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import { getLabelsToUpdate } from 'qonto/utils/persist-labels';

interface AttachmentsAttachmentViewerModalWrapperSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AttachmentsAttachmentViewerModalWrapperComponent extends Component<AttachmentsAttachmentViewerModalWrapperSignature> {
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  // @ts-expect-error
  @reads('organizationManager.organization') organization;

  // @ts-expect-error
  @tracked transaction;

  constructor(owner: unknown, args: AttachmentsAttachmentViewerModalWrapperSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    let { transactionId, onClose, ...rest } = this.args.data;
    // @ts-expect-error
    this.data = {
      ...rest,
      onSaveLabel: this.onSaveLabel,
      onSelectTag: this.handleSelectTag,
      markAsReviewTask: this.markAsReview,
      onRemove: this.handleRemove,
    };
    // @ts-expect-error
    this.transactionId = transactionId;
    // @ts-expect-error
    this.onClose = onClose;

    this.fetchTransactionModelAndRels.perform().catch(ignoreCancelation);
  }

  get modalData() {
    return {
      // @ts-expect-error
      ...this.data,
      transaction: this.transaction,
      isLoading: this.fetchTransactionModelAndRels.isRunning,
    };
  }

  @action
  // @ts-expect-error
  onSaveLabel(...args) {
    // @ts-expect-error
    this.handleSaveLabelTask.perform(this.transaction, ...args).catch(ignoreCancelation);
  }

  @action
  // @ts-expect-error
  markAsReview(...args) {
    // @ts-expect-error
    this.markAsReviewTask.perform(...args).catch(ignoreCancelation);
  }

  // @ts-expect-error
  async handleSelectTag(model, tagCode, source) {
    if (!source) {
      this.segment.track('transaction_category_selected', {
        source: 'transaction_details',
      });
    }
    try {
      await model.updateActivityTag(tagCode);
    } catch {
      // @ts-expect-error
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }

  handleRemove = () => {
    // @ts-expect-error
    this.args.data.onRemove();
    // @ts-expect-error
    this.args.close();
  };

  markAsReviewTask = task(async (isQualified, transactionIds) => {
    let organizationId = this.organization.id;

    if (isQualified) {
      await this.transaction.markAsQualify({ organizationId, transactionIds });
    } else {
      await this.transaction.markAsDisqualify({ organizationId, transactionIds });
    }
  });

  handleSaveLabelTask = task(async (transaction, labelList, label, source) => {
    transaction.labels = getLabelsToUpdate(this.transaction.labels, labelList, label);

    let result;
    let trackingEventName = 'transaction_analytic_label_edited';

    if (!label) {
      trackingEventName = 'transaction_analytic_label_deleted';
    }
    this.segment.track(trackingEventName, {
      source,
    });

    result = await this.transaction.save();

    return result;
  });

  fetchTransactionModelAndRels = restartableTask(async () => {
    // @ts-expect-error
    this.transaction = await this.store.findRecord('transaction', this.transactionId);
    await this.transaction.belongsTo('initiator').load();
    // @ts-expect-error
    this.attachments = await this.transaction.hasMany('attachments').load();
    // @ts-expect-error
    this.data.transaction = this.transaction;
    // @ts-expect-error
    this.data.attachments = this.attachments;
  });

  @action
  close() {
    // @ts-expect-error
    this.args.close();
    // @ts-expect-error
    this.onClose();
  }

  willDestroy() {
    super.willDestroy();
    // Refetch the transaction after closing the modal
    // @ts-expect-error
    this.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::AttachmentViewerModalWrapper': typeof AttachmentsAttachmentViewerModalWrapperComponent;
  }
}
