/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

interface FlowsApprovalWorkflowSidebarHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsApprovalWorkflowSidebarHeader extends Component<FlowsApprovalWorkflowSidebarHeaderSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];

  get shouldDisplayMilageSubtitle() {
    return (
      this.abilities.can('use mileages request') || this.abilities.can('review mileage request')
    );
  }

  get headerContent() {
    // @ts-expect-error
    switch (this.args.requestType) {
      case 'supplier_invoice':
        return {
          icon: 'supplier-invoice',
          title: this.intl.t(
            'approval-workflows.sidebar.header.type.supplier-invoice-requests.title'
          ),
          subtitle: this.intl.t(
            'approval-workflows.sidebar.header.type.supplier-invoice-requests.subtitle'
          ),
        };
      case 'reimbursement':
        return {
          icon: 'reimbursement',
          title: this.intl.t('approval-workflows.sidebar.header.type.reimbursement-requests.title'),
          subtitle: this.intl.t(
            'approval-workflows.sidebar.header.type.reimbursement-requests.subtitle',
            {
              canReadMileage: this.shouldDisplayMilageSubtitle,
            }
          ),
        };
      case 'transfer':
      default:
        return {
          icon: 'feature_transfer_direct-l',
          title: this.intl.t('approval-workflows.sidebar.header.type.transfer-requests.title'),
          subtitle: this.intl.t(
            'approval-workflows.sidebar.header.type.transfer-requests.subtitle'
          ),
        };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Sidebar::Header': typeof FlowsApprovalWorkflowSidebarHeader;
  }
}
