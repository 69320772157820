/* import __COLOCATED_TEMPLATE__ from './attachment-viewer-actions.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AttachmentViewerActionsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AttachmentViewerActions = templateOnlyComponent<AttachmentViewerActionsSignature>();

export default AttachmentViewerActions;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::AttachmentViewerActions': typeof AttachmentViewerActions;
  }
}
