/* import __COLOCATED_TEMPLATE__ from './conditions.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { generateConditionString } from 'qonto/utils/approval-workflow/condition';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

/**
 * TODO Move interfaces to the right place
 */
interface ApprovalWorkflowCondition {
  field: string;
  operator: string;
  value: {
    supplier_ids?: string[];
    uploader_ids?: string[];
    value?: string;
    currency?: string;
  };
}

interface SupplierSnapshot {
  currency: string;
  name: string;
  id: string;
  status: string;
  tin: string;
}
interface ApprovalWorkflowRulesetAttrVisualizerConditionsSignature {
  // The arguments accepted by the component
  Args: {
    conditions: ApprovalWorkflowCondition[];
    index: number;
    supplierSnapshot: SupplierSnapshot;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ApprovalWorkflowRulesetAttrVisualizerConditions extends Component<ApprovalWorkflowRulesetAttrVisualizerConditionsSignature> {
  @service declare intl: Services['intl'];
  @service declare errors: Services['errors'];
  @service declare store: Services['store'];
  @tracked conditions?: string;
  @tracked caption?: string;

  constructor(
    owner: unknown,
    args: ApprovalWorkflowRulesetAttrVisualizerConditionsSignature['Args']
  ) {
    super(owner, args);
    this.loadConditionsTask.perform().catch(ignoreCancelation);
  }

  loadConditionsTask = dropTask(async () => {
    let { condition, caption } = await generateConditionString(
      this.args.conditions[0],
      this.args.index || 0,
      this.store,
      this.intl,
      this.errors,
      this.args.supplierSnapshot
    );

    this.conditions = condition;
    this.caption = caption ?? null;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Visualizer::Conditions': typeof ApprovalWorkflowRulesetAttrVisualizerConditions;
  }
}
