/* import __COLOCATED_TEMPLATE__ from './attachment-viewer-dropzone.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';

import { FileUploadState } from 'qonto/services/attachments-manager';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface AttachmentViewerDropzoneSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AttachmentViewerDropzoneComponent extends Component<AttachmentViewerDropzoneSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked fileHover = false;
  @tracked files = [];

  get accept() {
    // @ts-expect-error
    return this.args.extensions?.split(',').map(ex => `.${ex}`);
  }

  get importingPopoverCopies() {
    return {
      'in-progress': 'attachment-viewer.bookkeeping.importing-modal.title.in-progress',
      errors: 'attachment-viewer.bookkeeping.importing-modal.title.errors',
      complete: 'attachment-viewer.bookkeeping.importing-modal.title.complete',
    };
  }

  @action
  // @ts-expect-error
  handleSaveAttachment(file) {
    let fileUploadState = new FileUploadState(file);

    // @ts-expect-error
    if (this.args.transaction.attachments.length > this.args.filesLimit - 1) {
      this.toastFlashMessages.toastError(
        this.intl.t('errors.files_limit', {
          // @ts-expect-error
          filesLimit: this.args.filesLimit,
        })
      );

      file.queue?.remove(file);
      return;
    }

    // @ts-expect-error
    if (file.size >= this.args.maxSize) {
      fileUploadState.errors = [
        this.intl.t('attachment-viewer.bookkeeping.importing-modal.error.file-size', {
          // @ts-expect-error
          maxSize: formatFileSize(this.intl, this.args.maxSize),
        }),
      ];

      file.queue?.remove(file);
    }

    // @ts-expect-error
    if (!this.args.extensions?.split(',').includes(file.extension)) {
      fileUploadState.errors = [
        this.intl.t('errors.wrong_file_extension', {
          extension: file.extension.toUpperCase(),
          extensions: new Intl.ListFormat(this.intl.primaryLocale, {
            type: 'disjunction',
            // @ts-expect-error
          }).format(this.args.extensions.split(',').map(extension => extension.toUpperCase())),
        }),
      ];

      file.queue?.remove(file);
    }

    // @ts-expect-error
    this.files = [...this.files, fileUploadState];

    if (fileUploadState.errors.length) {
      return;
    }

    return (
      this.args
        // @ts-expect-error
        .saveAttachment(this.args.transaction, file)
        .then(() => {
          if (file.state === 'failed') {
            fileUploadState.errors = [
              this.intl.t('attachment-viewer.bookkeeping.importing-modal.error.generic'),
            ];
            file.queue?.remove(file);
          }
          // @ts-expect-error
          if (this.files.every(file => file.finishedUploading && !file.errors?.length)) {
            this.onClose();
          }
        })
        .catch(ignoreCancelation)
        .catch(() => {
          this.toastFlashMessages.toastError(
            this.intl.t('attachment-viewer.bookkeeping.importing-modal.error.generic')
          );
        })
    );
  }

  @action
  // @ts-expect-error
  handleErrorFile(error) {
    this.toastFlashMessages.toastError(error);
  }

  @action
  onEnter() {
    this.fileHover = true;
  }

  @action
  onLeave() {
    this.fileHover = false;
    // @ts-expect-error
    this.args.onLeave();
  }

  @action
  onClose() {
    this.files = [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::AttachmentViewerDropzone': typeof AttachmentViewerDropzoneComponent;
  }
}
