/* import __COLOCATED_TEMPLATE__ from './probative-status.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ProbativeStatusSignature {
  Element: HTMLElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ProbativeStatus = templateOnlyComponent<ProbativeStatusSignature>();

export default ProbativeStatus;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::Sidebar::ProbativeStatus': typeof ProbativeStatus;
  }
}
