/* import __COLOCATED_TEMPLATE__ from './association-kyb-pending-modal.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { alias } from 'macro-decorators';

interface AssociationKybPendingModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AssociationKybPendingModal extends Component<AssociationKybPendingModalSignature> {
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @alias('organizationManager.organization.associationTypeformUrl') associationTypeformUrl;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AssociationKybPendingModal: typeof AssociationKybPendingModal;
  }
}
