/* import __COLOCATED_TEMPLATE__ from './bookkeeping.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { not } from 'macro-decorators';

import { CashflowCategoryManager } from 'qonto/react/components/transactions/sidebar/category/cashflow-category';
// @ts-expect-error
import transactionHasVat from 'qonto/utils/transaction-has-vat';

interface AttachmentsSidebarBookkeepingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AttachmentsSidebarBookkeepingComponent extends Component<AttachmentsSidebarBookkeepingSignature> {
  // @ts-expect-error
  @not('args.transaction.declined') hasAccountingValue;

  @service declare segment: Services['segment'];
  @service declare abilities: Services['abilities'];
  @service declare categorizationRulesManager: Services['categorizationRulesManager'];

  CashflowCategoryManager = CashflowCategoryManager;

  get isActivityTagSelectDisabled() {
    // @ts-expect-error
    let { isTax, isPayLater, credit } = this.args.transaction;
    return isTax || (isPayLater && credit);
  }

  get canDisplayVAT() {
    // @ts-expect-error
    return this.abilities.can('view vat bookkeeping') && transactionHasVat(this.args.transaction);
  }

  get isVatDisabled() {
    // @ts-expect-error
    let { isFee } = this.args.transaction;
    return isFee;
  }

  get showAnalyticLabels() {
    return (
      this.abilities.can('access custom-label') &&
      this.abilities.can('read custom-label') &&
      this.hasAccountingValue
    );
  }

  get activeCategoryId() {
    // @ts-expect-error
    return this.categorizationRulesManager.getActiveCategoryId([this.args.transaction]);
  }

  @action
  // @ts-expect-error
  handleActivityTagSelect(activityTagCode) {
    let source = 'bookkeeping_details';
    this.segment.track('transaction_category_selected', {
      source,
    });
    // @ts-expect-error
    this.args.selectTag(this.args.transaction, activityTagCode, source);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::Sidebar::Bookkeeping': typeof AttachmentsSidebarBookkeepingComponent;
  }
}
