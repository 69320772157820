export default {
  "attachment-viewer": "Zo",
  "sidebar": "Zn",
  "attachment-viewer-details": "Zi",
  "attachment-viewer-filename": "ZA body-2",
  "attachment-viewer-actions": "ZY",
  "dropzone": "Zh",
  "dropzone-visible": "Zf",
  "attachment-main": "ZK"
};
