import { template as template_a5b070acbbdd48288254714d3bf3bec8 } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import FormFieldsAmountField from '@qonto/ui-kit/components/form-fields/amount-field';
import Tooltip from '@qonto/ui-kit/components/tooltip';
import { t } from 'ember-intl';
import { variation } from 'ember-launch-darkly';
import eq from 'ember-truth-helpers/helpers/eq';
import SelectMultiple from 'qonto/components/approval-workflow/form/select-multiple';
import { VALIDATION_ERRORS } from 'qonto/constants/approval-workflow';
import { MEMBER_STATUS } from 'qonto/constants/membership';
import styles from './condition.strict-module.css';
import ApprovalWorkflowConditionInfo from './condition/info.js';
import ConditionTypeSelector from './condition/type-selector.js';
export const TYPES = {
    SUPPLIER: 'supplier_id',
    AMOUNT: 'amount',
    UPLOADER: 'uploader_id'
};
const OPERATIONS = {
    GTE: 'gte',
    ONE_OF: 'one_of'
};
export default class ApprovalWorkflowCondition extends Component {
    @service
    intl;
    @service
    organizationManager;
    @service
    store;
    TYPE_OPTIONS = {
        SUPPLIER: {
            key: TYPES.SUPPLIER,
            value: this.intl.t('approval-workflows.form.conditions.types.supplier')
        },
        AMOUNT: {
            key: TYPES.AMOUNT,
            value: this.intl.t('approval-workflows.form.conditions.types.amount')
        },
        UPLOADER: {
            key: TYPES.UPLOADER,
            value: this.intl.t('approval-workflows.form.conditions.types.import')
        }
    };
    VALIDATION_ERROR_KEYS = {
        [VALIDATION_ERRORS.CONDITION_AMOUNT_EMPTY]: this.intl.t('approval-workflows.errors.conditions.value.empty'),
        [VALIDATION_ERRORS.CONDITION_AMOUNT_LOWER]: this.intl.t('approval-workflows.errors.conditions.value.lower'),
        [VALIDATION_ERRORS.CONDITION_AMOUNT_HIGHER]: this.intl.t('approval-workflows.errors.conditions.value.higher'),
        [VALIDATION_ERRORS.CONDITION_AMOUNT_SAME]: this.intl.t('approval-workflows.errors.conditions.value.same'),
        [VALIDATION_ERRORS.SUPPLIER_IDS_ARCHIVED]: this.intl.t('approval-workflows.errors.conditions.value.suppliers-archived'),
        [VALIDATION_ERRORS.SUPPLIER_IDS_BLANK]: this.intl.t('approval-workflows.errors.steps.supplier.empty'),
        [VALIDATION_ERRORS.UPLOADER_IDS_BLANK]: this.intl.t('approval-workflows.errors.steps.member.empty'),
        [VALIDATION_ERRORS.UPLOADER_IDS_INACTIVE]: this.intl.t('approval-workflows.errors.steps.approver.change')
    };
    get hasErrors() {
        return this.args.errors?.length > 0;
    }
    get errorMessage() {
        if (this.hasErrors) {
            let message = this.VALIDATION_ERROR_KEYS[this.args.errors[0].message];
            if (message) return message;
            return this.intl.t('validations.errors.invalid');
        }
        return null;
    }
    // Remove with the feature--boolean-improve-builder-aw4si flag
    get isConditionTypeSelectorDisabled() {
        return !variation('feature--boolean-improve-builder-aw4si') && this.isNotFirstCondition;
    }
    get isNotFirstCondition() {
        return this.args.index > 0;
    }
    get amountInputLabel() {
        if (this.isNotFirstCondition) return this.intl.t('approval-workflows.form.conditions.gte');
        return this.intl.t('approval-workflows.form.conditions.gt');
    }
    get shouldDisplayCaption() {
        let { nextRulesetConditionAmount } = this.args;
        if (nextRulesetConditionAmount) {
            let nextRulesetConditionAmountToInt = parseFloat(nextRulesetConditionAmount, 10);
            return nextRulesetConditionAmountToInt !== 0 && !this.hasErrors;
        }
        return false;
    }
    get linkingText() {
        switch(this.typeSelected?.key){
            case TYPES.SUPPLIER:
                return this.intl.t('approval-workflows.form.conditions.label.eq');
            case TYPES.AMOUNT:
                return this.isNotFirstCondition ? this.intl.t('approval-workflows.form.conditions.label.gte') : this.intl.t('approval-workflows.form.conditions.label.gt');
            case TYPES.UPLOADER:
                return this.intl.t('approval-workflows.form.conditions.label.made-by');
        }
    }
    get typeSelected() {
        return this.typeOptions.find(({ key })=>key === this.args.condition?.field);
    }
    get typeOptions() {
        return Object.values(this.TYPE_OPTIONS);
    }
    onTypeChange = (choice)=>{
        this.args.onUpdate({
            field: choice.key,
            operation: choice.key === TYPES.AMOUNT ? OPERATIONS.GTE : OPERATIONS.ONE_OF,
            value: choice.key === TYPES.AMOUNT ? {
                currency: 'EUR',
                value: '0.00'
            } : null
        });
    };
    get displayAmountOnly() {
        return this.args.amountOnly;
    }
    updateAmount = (value)=>{
        this.args.onUpdate({
            value: {
                currency: 'EUR',
                value
            },
            operation: OPERATIONS.GTE
        });
    };
    querySuppliers = async (query, initialLoad = false)=>{
        // First fetch suppliers matching the query
        let suppliers = await this.store.query('supplier', {
            organization_id: this.organizationManager.organization?.id,
            search: query,
            per_page: 100
        });
        let mappedSuppliers = suppliers.map(this.supplierToOption);
        // If there are selected supplier IDs, ensure they're included in the results
        if (initialLoad && this.selectedSupplierIds?.length) {
            // Get IDs of suppliers already fetched
            let fetchedIds = mappedSuppliers.map((option)=>option.key);
            // Find missing selected supplier IDs
            let missingIds = this.selectedSupplierIds.filter((id)=>!fetchedIds.includes(id));
            // If we have missing IDs, fetch them specifically
            if (missingIds.length > 0) {
                let missingSuppliers = await this.store.query('supplier', {
                    organization_id: this.organizationManager.organization?.id,
                    filter: {
                        id: missingIds
                    }
                });
                // Add missing suppliers to the results
                mappedSuppliers = [
                    ...mappedSuppliers,
                    ...missingSuppliers.map(this.supplierToOption)
                ];
            }
        }
        return mappedSuppliers;
    };
    updateSuppliers = (selected)=>{
        this.args.onUpdate({
            value: {
                supplier_ids: selected?.map(({ key })=>key) ?? []
            },
            operation: OPERATIONS.ONE_OF
        });
    };
    get selectedSupplierIds() {
        return this.args.condition.value.supplier_ids;
    }
    queryUploaders = (query)=>{
        return this.organizationManager.findMembers(query, {
            status: [
                MEMBER_STATUS.ACTIVE
            ]
        }).then((members)=>members.map(this.uploaderToOption));
    };
    updateUploaders = (selected)=>{
        this.args.onUpdate({
            value: {
                uploader_ids: selected?.map(({ key })=>key) ?? []
            },
            operation: OPERATIONS.ONE_OF
        });
    };
    get selectedUploaderIds() {
        return this.args.condition.value.uploader_ids;
    }
    supplierToOption = ({ id, name })=>{
        return {
            key: id,
            value: name
        };
    };
    uploaderToOption = ({ id, fullName })=>{
        return {
            key: id,
            value: fullName
        };
    };
    // Remove with the feature--boolean-improve-builder-aw4si flag
    get conditionTypeTooltipMessage() {
        let conditionType;
        switch(this.typeSelected.key){
            case TYPES.SUPPLIER:
                conditionType = this.intl.t('approval-workflows.form.conditions.types.supplier-plural');
                break;
            case TYPES.AMOUNT:
                conditionType = this.intl.t('approval-workflows.form.conditions.types.amount-plural');
                break;
            case TYPES.UPLOADER:
                conditionType = this.intl.t('approval-workflows.form.conditions.types.import-plural');
                break;
        }
        return this.intl.t('approval-workflows.requests.status.tooltip-condition-type', {
            condition_type: conditionType
        });
    }
    // Remove with the feature--boolean-improve-builder-aw4si flag
    get shouldHideConditionTypeTooltip() {
        return variation('feature--boolean-improve-builder-aw4si') || !this.isNotFirstCondition;
    }
    static{
        template_a5b070acbbdd48288254714d3bf3bec8(`
    <div class={{styles.amount-field}} data-test-approval-workflow-form-condition-amount={{@index}}>
      {{#if this.displayAmountOnly}}
        <FormFieldsAmountField
          @label={{this.amountInputLabel}}
          @disabled={{@disabled}}
          @updateAmount={{this.updateAmount}}
          @amount={{@condition.value.value}}
          @errorMessage={{this.errorMessage}}
          @min={{0}}
          {{on 'focusout' @validate}}
          data-test-workflow-form-condition-amount-step
          ...attributes
        />
      {{else}}
        <fieldset
          class={{styles.condition-selector}}
          data-test-workflow-form-condition-type-selector
        >
          <legend class='mb-8'>{{t 'approval-workflows.form.conditions.when'}}</legend>
          {{!  Remove the tooltip with the feature--boolean-improve-builder-aw4si flag }}
          <Tooltip
            @message={{this.conditionTypeTooltipMessage}}
            @position='top center'
            @disabled={{this.shouldHideConditionTypeTooltip}}
          >
            <ConditionTypeSelector
              class={{styles.condition-type}}
              @selected={{this.typeSelected}}
              @options={{this.typeOptions}}
              @onChange={{this.onTypeChange}}
              @disabled={{this.isConditionTypeSelectorDisabled}}
            />
          </Tooltip>
          <span
            class={{styles.condition-operator}}
            data-test-linking-text
          >{{this.linkingText}}</span>
          {{#if (eq this.typeSelected.key TYPES.SUPPLIER)}}
            <SelectMultiple
              class={{styles.condition-value}}
              @errorMessage={{this.errorMessage}}
              @placeholder={{t 'approval-workflows.form.conditions.placeholder.suppliers'}}
              @query={{this.querySuppliers}}
              @update={{this.updateSuppliers}}
              @selected={{this.selectedSupplierIds}}
              @selectedAndDisabled={{@selectedSupplierIdsInOtherConditions}}
              data-test-workflow-form-condition
              data-test-workflow-form-condition-suppliers
            />
          {{else if (eq this.typeSelected.key TYPES.UPLOADER)}}
            <SelectMultiple
              class={{styles.condition-value}}
              @errorMessage={{this.errorMessage}}
              @mapToOption={{this.uploaderToOption}}
              @placeholder={{t 'approval-workflows.form.placeholder.approver'}}
              @query={{this.queryUploaders}}
              @update={{this.updateUploaders}}
              @selected={{this.selectedUploaderIds}}
              @selectedAndDisabled={{@selectedUploaderIdsInOtherConditions}}
              data-test-workflow-form-condition
              data-test-workflow-form-condition-uploaders
            />
          {{else}}
            <FormFieldsAmountField
              class={{styles.condition-value}}
              @disabled={{@disabled}}
              @updateAmount={{this.updateAmount}}
              @amount={{@condition.value.value}}
              @errorMessage={{this.errorMessage}}
              @min={{0}}
              {{on 'focusout' @validate}}
              data-test-workflow-form-condition
              data-test-workflow-form-condition-amount-step
            />
          {{/if}}
        </fieldset>
      {{/if}}
      <ApprovalWorkflowConditionInfo
        @isVisible={{this.shouldDisplayCaption}}
        @conditionAmount={{@condition.value.value}}
        @nextRulesetConditionAmount={{@nextRulesetConditionAmount}}
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
