export default {
  "main": "Zu",
  "spinner": "RS",
  "error": "Rc",
  "error-illustration": "Rq",
  "wrapper": "RZ",
  "content": "RR",
  "illustration": "RQ mb-32",
  "title": "Re mb-16 title-2",
  "subtitle": "RB body-2"
};
