/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface ApprovalWorkflowRulesetAttrVisualizerSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ApprovalWorkflowRulesetAttrVisualizerSuccess extends Component<ApprovalWorkflowRulesetAttrVisualizerSuccessSignature> {
  @service declare intl: Services['intl'];

  get successCopy() {
    // @ts-expect-error
    let { requestType } = this.args;

    switch (requestType) {
      case 'transfer':
        return this.intl.t('approval-workflows.ruleset.steps.approval.transfer-requests');
      case 'supplier_invoice':
        return this.intl.t('approval-workflows.ruleset.steps.payment-completed');
      case 'reimbursement':
      case 'expense_report':
      case 'mileage':
      default:
        return this.intl.t('approval-workflows.ruleset.steps.approval.reimbursement-requests');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Visualizer::Success': typeof ApprovalWorkflowRulesetAttrVisualizerSuccess;
  }
}
