/* import __COLOCATED_TEMPLATE__ from './visualizer.hbs'; */
// @ts-nocheck
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

import { REQUEST_TYPES } from 'qonto/constants/approval-workflow';

interface ApprovalWorkflowRulesetVisualizerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ApprovalWorkflowRulesetVisualizer extends Component<ApprovalWorkflowRulesetVisualizerSignature> {
  get rulesetsWithStepsAggregatedByOrder() {
    // @ts-expect-error
    let { rulesets } = this.args;

    let groupedApprovers = this.transformRulesets(rulesets);

    return groupedApprovers ?? [];
  }

  // @ts-expect-error
  isLastStep(ruleset, index) {
    return index === ruleset.steps.length - 1;
  }

  // @ts-expect-error
  displayRulesetDisclaimer(requestType, index) {
    return (
      variation('feature--boolean-improve-builder-aw4si') &&
      requestType === REQUEST_TYPES.SUPPLIER_INVOICE &&
      index > 0
    );
  }

  /**
   * Combines steps that have the same order into a single step with values aggregated in an array.
   *
   * @param {Array} steps - An array of step objects that need to be combined.
   * @returns {Array} An array of combined step objects grouped by their order.
   */
  // @ts-expect-error
  combineStepsFromSameOrder = steps => {
    // @ts-expect-error
    let orderMap = steps.reduce((accumulator, step) => {
      if (!accumulator[step.order]) {
        accumulator[step.order] = {
          field: step.field,
          operation: step.operation,
          value: [],
          order: step.order,
          id: step.id,
        };
      }
      accumulator[step.order].value.push(step.value);
      return accumulator;
    }, {});

    return Object.values(orderMap);
  };

  // @ts-expect-error
  transformRulesets = rulesets => {
    // @ts-expect-error
    return rulesets.map(ruleset => {
      return {
        conditions: ruleset.conditions,
        steps: this.combineStepsFromSameOrder(ruleset.steps),
      };
    });
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ApprovalWorkflow::Ruleset::Visualizer': typeof ApprovalWorkflowRulesetVisualizer;
  }
}
