/* import __COLOCATED_TEMPLATE__ from './placeholder.hbs'; */
import Component from '@glimmer/component';

import { Placeholder as BookkeepingPlaceholder } from 'qonto/react/components/attachments/sidebar/bookkeeping/placeholder';

interface PlaceholderSignature {
  Element: HTMLElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class Placeholder extends Component<PlaceholderSignature> {
  placeholder = BookkeepingPlaceholder;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::AttachmentViewerSidebar::Placeholder': typeof Placeholder;
  }
}
