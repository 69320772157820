export default {
  "aside": "ZF",
  "aside-section": "Zg",
  "aside-content": "ZD",
  "aside-footer": "ZV",
  "aside-title": "ZL caption-bold",
  "accountant-upsell": "ZT",
  "file-status": "Zz",
  "file-status-icon": "Za",
  "file-status-text": "ZH",
  "file-status-label": "ZO body-1",
  "file-status-description": "Zm body-2"
};
