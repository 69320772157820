/* import __COLOCATED_TEMPLATE__ from './pagopa.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface AttachmentsSidebarBookkeepingPagopaSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AttachmentsSidebarBookkeepingPagopaComponent extends Component<AttachmentsSidebarBookkeepingPagopaSignature> {
  @service declare abilities: Services['abilities'];

  get showAnalyticLabels() {
    return this.abilities.can('access custom-label') && this.abilities.can('read custom-label');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::Sidebar::Bookkeeping::Pagopa': typeof AttachmentsSidebarBookkeepingPagopaComponent;
  }
}
